import { ReactText } from 'react'
import { useIntl } from 'react-intl'

export type TTranslationProps = {
  translationId: string
  translationValues?: { [placeholderName: string]: ReactText }
}

export const useTranslation = ({ translationId, translationValues }: TTranslationProps) => {
  const { messages, formatMessage } = useIntl()

  const formattedMessage = !messages[translationId]
    ? translationId
    : formatMessage({ id: translationId }, translationValues)

  return {
    formattedMessage,
  }
}
