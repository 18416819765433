import { PropsWithChildren } from 'react'
import { IntlProvider as BaseIntlProvider } from '@hfs/i18n/components/IntlProvider'
import { Country, Language } from '@hfs/types/locale'

const getTranslations = async (locale: string) => ({
  ...(await import(`@hfs/ui/i18n/dist/translations.${locale}.json`)),
  ...(await import(`@hfs/website/i18n/dist/translations.${locale}.json`)),
})

type Props = {
  country: Country
  language: Language
}

export const IntlProvider = ({ children, country, language }: NonNullable<PropsWithChildren<Props>>) => (
  <BaseIntlProvider country={country} getTranslations={getTranslations} language={language}>
    {children}
  </BaseIntlProvider>
)
