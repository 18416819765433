import { lazy } from 'react'
import { Route } from '@hfs/react-router'
import { paths } from '@hfs/website/routes/paths'

export const news = [
  <Route
    key={paths.news}
    component={lazy(async () => {
      const { News } = await import(
        /* webpackPrefetch: true, webpackChunkName: "News" */ '@hfs/website/components/pages/public/News'
      )

      return {
        default: News,
      }
    })}
    exact
    path={paths.news}
  />,
]
