import { CurrentEnvironment } from '../getCurrentEnvironment'

export const getApiEndpoint = (environment: CurrentEnvironment) => {
  if (environment.development) {
    return 'https://www.homeforstudents.dev'
  }

  if (environment.staging) {
    // FIXME this should be staging.homeforstudents.it and protected through a VPN
    return 'https://hfsdemo.smart-consult.it'
  }

  return 'https://www.homeforstudents.it'
}
