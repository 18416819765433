import { css } from 'styled-components'

export type TDisabledProps = {
  disabled?: boolean
}

export const disabled = css<TDisabledProps>`
  ${({ disabled: disabledProp }) =>
    disabledProp
      ? css`
          opacity: 0.6;
        `
      : ''}
`
