import { lazy } from 'react'
import { Route } from '@hfs/react-router'
import { paths } from '@hfs/website/routes/paths'

export const rooms = [
  <Route
    key={paths.room}
    component={lazy(async () => {
      const { Room } = await import(
        /* webpackPrefetch: true, webpackChunkName: "Room" */ '@hfs/website/components/pages/public/Room'
      )

      return {
        default: Room,
      }
    })}
    exact
    path={paths.room}
  />,
  <Route
    key={paths.rooms}
    component={lazy(async () => {
      const { Rooms } = await import(
        /* webpackPrefetch: true, webpackChunkName: "Rooms" */ '@hfs/website/components/pages/public/Rooms'
      )

      return {
        default: Rooms,
      }
    })}
    exact
    path={paths.rooms}
  />,
]
