import { lazy } from 'react'
import { Route } from '@hfs/react-router'
import { paths } from '@hfs/website/routes/paths'

export const homepage = [
  <Route
    key={paths.homepage}
    component={lazy(async () => {
      const { Homepage } = await import(
        /* webpackPrefetch: true, webpackChunkName: "Homepage" */ '@hfs/website/components/pages/public/Homepage'
      )

      return {
        default: Homepage,
      }
    })}
    exact
    path={paths.homepage}
  />,
]
