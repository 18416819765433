import { css } from 'styled-components'
import { colors, TColorName } from '@hfs/ui/tokens/colors'
import { transition } from '../transition'

type TParam = {
  backgroundColorName?: TColorName
}

export const background = ({ backgroundColorName }: TParam) => {
  if (backgroundColorName) {
    return css`
      background-color: ${colors[backgroundColorName]};

      ${transition([{ millisecondsDuration: 250, prop: 'background-color' }])}
    `
  }

  return ''
}

export type TBackgroundParams = TParam
