import styled from 'styled-components'
import { Image } from '@hfs/ui/components/Image'
import LogoImage from './logo.png'

type Props = {
  alt?: string
  className?: string
  height?: number
  width?: number
}

const BaseLogo = ({ alt = 'logo', className, height, width }: Props) => (
  <Image alt={alt} className={className} height={height} src={LogoImage} width={width} />
)

export const Logo = (props: Props) => <BaseLogo {...props} />

export const WhiteLogo = styled(BaseLogo).attrs(() => ({ alt: 'white-logo' }))`
  filter: brightness(0) invert(1);
`
