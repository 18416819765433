import { css } from 'styled-components'

type TParam = {
  delay?: number
  millisecondsDuration: number
  prop: string
}[]

export const transition = (rules: TParam) => css`
  transition: ${rules
    .map(({ delay = 0, millisecondsDuration, prop }) => `${prop} ${millisecondsDuration}ms ${delay}ms`)
    .join(',')};
  will-change: ${rules.map(rule => rule.prop).join(',')};
`
