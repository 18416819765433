import { MouseEvent } from 'react'
import styled from 'styled-components'
import { TColorName, colors } from '@hfs/ui/tokens/colors'
import {
  fontFamily,
  TTypographyFontSize,
  TTypographyFontWeight,
  TTypographyLineHeight,
} from '@hfs/ui/tokens/typography'
import { disabled } from '@hfs/ui/utils/style/disabled'
import { ellipse } from './style'

export type TTypographyProps = {
  align?: 'left' | 'center' | 'right'
  breakWord?: boolean
  colorName?: TColorName
  disabled?: boolean
  ellipsis?: boolean
  fontStyle?: 'italic' | 'normal'
  onClick?: (event: MouseEvent<HTMLSpanElement>) => void | Promise<void>
  strikeThrough?: boolean
  typographyFontSize: TTypographyFontSize
  typographyFontWeight?: TTypographyFontWeight
  typographyLineHeight: TTypographyLineHeight
}

const TextComponent = styled.span<TTypographyProps>`
  ${ellipse}
  ${disabled}

  color: ${({ colorName = 'black' }) => colors[colorName]};
  font-family: ${fontFamily};
  font-size: ${({ typographyFontSize }) => typographyFontSize}px;
  font-style: ${({ fontStyle = 'normal' }) => fontStyle};
  font-weight: ${({ typographyFontWeight = 400 }) => typographyFontWeight};
  line-height: ${({ typographyLineHeight }) => typographyLineHeight}px;
  text-align: ${({ align = 'left' }) => align};
  text-decoration-line: ${({ strikeThrough = false }) => (strikeThrough ? 'line-through' : 'none')};
  word-break: ${({ breakWord = false }) => (breakWord ? 'break-word' : 'normal')};
`

export const Text = styled(TextComponent)<TTypographyProps>``
