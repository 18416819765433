import 'react-toastify/dist/ReactToastify.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { render } from 'react-dom'
import { getApiEndpoint } from '@hfs/configuration/apiEndpoint'
import { serverEnvironment } from '@hfs/configuration/serverEnvironment'
import { App } from './App'
import { Config } from './types/config'

library.add(fab as any)
library.add(fas)
export default class AppSetup {
  constructor(private appContainerId: string, private config: Config) {}

  setup() {
    render(
      <App
        config={{
          baseUrl: getApiEndpoint(serverEnvironment),
          country: this.config.country,
          language: this.config.language,
        }}
      />,
      document.getElementById(this.appContainerId)
    )
  }
}

;(window as any).PackageManager = AppSetup
