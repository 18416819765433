import { MemoryRouter as Router } from '@hfs/react-router'
import { Column } from '@hfs/ui/components/Column'
import { Loader } from '@hfs/ui/components/Loader'
import { Logo } from '@hfs/ui/illustrations/Logo'
import { FooterWrapper } from './FooterWrapper'
import { PageWrapper } from './PageWrapper'

export const FallbackLoader = () => (
  <Router>
    <PageWrapper align="center">
      <Column align="center" grow={1}>
        <Loader fill="r900" height={56} loading width={56} />
      </Column>

      <FooterWrapper justify="center">
        <Logo width={280} />
      </FooterWrapper>
    </PageWrapper>
  </Router>
)
