import { css } from 'styled-components'
import { TTypographyProps } from '..'

export const ellipse = ({ ellipsis }: TTypographyProps) => {
  if (ellipsis) {
    return css`
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `
  }

  return ''
}
