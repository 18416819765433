import { useEffect, useState } from 'react'
import { throttle } from '@hfs/utils/functions/throttle'

const isMobile = (clientWidth: number) => clientWidth >= 0

const isTablet = (clientWidth: number) => clientWidth >= 768

const isDesktop = (clientWidth: number) => clientWidth > 1024

type State = {
  clientHeight: number
  clientWidth: number
}

export const useViewport = () => {
  const [{ clientWidth }, setState] = useState<State>({
    clientHeight: document.documentElement.clientHeight,
    clientWidth: document.documentElement.clientWidth,
  })

  useEffect(() => {
    const eventListener = throttle(() => {
      const { clientHeight: currentClientHeight, clientWidth: currentClientWidth } = document.documentElement

      setState({
        clientHeight: currentClientHeight,
        clientWidth: currentClientWidth,
      })
    }, 300)

    window.addEventListener('resize', eventListener)

    return () => {
      window.removeEventListener('resize', eventListener)
    }
  }, [setState])

  return {
    isDesktop: isDesktop(clientWidth),
    isMobile: isMobile(clientWidth),
    isMobileOnly: !isTablet(clientWidth),
    isTablet: isTablet(clientWidth),
    isTabletOnly: isTablet(clientWidth) && !isDesktop(clientWidth),
  }
}
