import { lazy } from 'react'
import { Route } from '@hfs/react-router'
import { paths } from '@hfs/website/routes/paths'

export const aboutUs = [
  <Route
    key={paths.aboutUs}
    component={lazy(async () => {
      const { AboutUs } = await import(
        /* webpackPrefetch: true, webpackChunkName: "AboutUs" */ '@hfs/website/components/pages/public/AboutUs'
      )

      return {
        default: AboutUs,
      }
    })}
    exact
    path={paths.aboutUs}
  />,
]
