import { Environment } from '../environmentCodec'

type EnvironmentConfig<E extends Environment> = {
  [Name in Exclude<Environment, E>]: false
} &
  {
    [Name in E]: true
  }

export const getCurrentEnvironment = (environment: Environment) => {
  switch (environment) {
    case 'production': {
      return {
        development: false,
        production: true,
        staging: false,
      } as EnvironmentConfig<'production'>
    }

    case 'staging': {
      return {
        development: false,
        production: false,
        staging: true,
      } as EnvironmentConfig<'staging'>
    }

    default: {
      return {
        development: true,
        production: false,
        staging: false,
      } as EnvironmentConfig<'development'>
    }
  }
}

export type CurrentEnvironment = ReturnType<typeof getCurrentEnvironment>
