import { css } from 'styled-components'

type TAlign = 'baseline' | 'center' | 'flex-end' | 'flex-start' | 'inherit' | 'initial' | 'stretch' | 'unset'

type TJustify =
  | 'baseline'
  | 'center'
  | 'end'
  | 'first baseline'
  | 'flex-end'
  | 'flex-start'
  | 'inherit'
  | 'initial'
  | 'last-baseline'
  | 'left'
  | 'right'
  | 'safe'
  | 'space-around'
  | 'space-between'
  | 'space-evenly'
  | 'start'
  | 'stretch'
  | 'unsafe'
  | 'unset'

export type TParam = {
  align?: TAlign
  alignSelf?: 'auto' | TAlign
  basis?: string
  flexDir?: 'column' | 'column-reverse' | 'row' | 'row-reverse'
  flexWrap?: 'inherit' | 'initial' | 'nowrap' | 'unset' | 'wrap' | 'wrap-reverse'
  grow?: number
  justify?: TJustify
  justifySelf?: 'auto' | TJustify
  shrink?: number
}

export const flex = css<TParam>`
  display: flex;
  min-height: 0;
  min-width: 0;

  ${({ align, alignSelf, basis, flexDir, flexWrap, grow, justify, justifySelf, shrink }) => css`
    align-items: ${align};
    align-self: ${alignSelf};
    flex-basis: ${basis};
    flex-direction: ${flexDir};
    flex-grow: ${grow};
    flex-shrink: ${shrink};
    flex-wrap: ${flexWrap};
    justify-content: ${justify};
    justify-self: ${justifySelf};
  `}
`

export type TFlexParam = TParam
