import { createContext, ReactNode, useContext } from 'react'
import { Config } from '@hfs/website/types/config'

export type ConfigContext = Config

const ConfigContext = createContext<ConfigContext>({ baseUrl: '', country: 'IT', language: 'it' })

type Props = {
  children: ReactNode
  config: ConfigContext
}

export const ConfigProvider = ({ children, config }: Props) => {
  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
}

export const useConfig = () => useContext(ConfigContext)
