import { Suspense } from 'react'
import { hot } from 'react-hot-loader'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ToastContainer } from 'react-toastify'
import { environment } from '@hfs/configuration/environment'
import { BrowserRouter as Router, Switch } from '@hfs/react-router'
import { GlobalStyle } from '@hfs/ui/components/GlobalStyle'
import { DialogsProvider } from '@hfs/ui/contexts/Dialogs'
import { FallbackLoader } from './components/layout/FallbackLoader'
import { GdprBanner } from './components/layout/GdprBanner'
import { ConfigProvider } from './components/providers/Config'
import { IntlProvider } from './components/providers/IntlProvider'
import { routesConfiguration } from './routes/configuration'
import { Config } from './types/config'

const client = new QueryClient()

type Props = {
  config: Config
}

export const App = hot(module)(({ config }: Props) => {
  const basename = !environment.development ? `/${config.language}` : ''

  return (
    <GlobalStyle>
      <DialogsProvider>
        <QueryClientProvider client={client}>
          <IntlProvider country={config.country} language={config.language}>
            <ConfigProvider config={config}>
              <ToastContainer />
              <ReactQueryDevtools />

              <Router basename={basename}>
                <Suspense fallback={<FallbackLoader />}>
                  <GdprBanner />

                  <Switch>{routesConfiguration}</Switch>
                </Suspense>
              </Router>
            </ConfigProvider>
          </IntlProvider>
        </QueryClientProvider>
      </DialogsProvider>
    </GlobalStyle>
  )
})
