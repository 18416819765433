import { createContext, PropsWithChildren, useCallback, useMemo, useReducer } from 'react'

type Action = {
  type: 'close' | 'open'
  id: string
}

const reducer = (state: string[], action: Action) => {
  switch (action.type) {
    case 'close': {
      return state.filter(id => id !== action.id)
    }

    case 'open': {
      if (state.some(id => action.id === id)) {
        return state
      }

      return [...state, action.id]
    }

    default: {
      return state
    }
  }
}

type DialogsContext = {
  close: (id: string) => void
  has: (id: string) => boolean
  open: (id: string) => void
}

export const DialogsContext = createContext<DialogsContext>({ close: () => ({}), has: () => false, open: () => ({}) })

export const DialogsProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [dialogs, dispatch] = useReducer(reducer, [])

  const close = useCallback(
    (id: string) =>
      dispatch({
        id,
        type: 'close',
      }),
    []
  )

  const has = useCallback((id: string) => dialogs.includes(id), [dialogs])

  const open = useCallback(
    (id: string) =>
      dispatch({
        id,
        type: 'open',
      }),
    []
  )

  const state = useMemo(() => ({ close, has, open }), [close, has, open])

  return <DialogsContext.Provider value={state}>{children}</DialogsContext.Provider>
}
