export const paths = {
  aboutUs: '/about-us',
  contactUs: '/contact-us',
  contractsAndRules: '/contracts-and-rules',
  homepage: '/',
  news: '/news',
  noMatch: '*',
  room: '/rooms/:roomId',
  rooms: '/rooms',
} as const
