import { css } from 'styled-components'
import { colors } from '@hfs/ui/tokens/colors'
import { fontFamily } from '@hfs/ui/tokens/typography'

const ns = 'Toastify'

const toastSign = css`
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 8px;
`

export const toastify = css`
  .${ns} {
    font-family: ${fontFamily};

    &__toast-container {
      right: 16px;
      position: fixed;
      z-index: 1000000;
    }

    &__close-button {
      color: ${colors.n300};

      &:hover {
        color: ${colors.black};
      }
    }

    &__toast {
      border-radius: 2px;
      box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.15);
      color: ${colors.black};
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 16px;
      overflow: hidden;
      padding: 16px 16px 16px 24px;
      position: relative;

      &--default {
        &::before {
          background-color: ${colors.black};

          ${toastSign}
        }
      }

      &--dark {
        background-color: ${colors.black};

        color: ${colors.white};

        &::before {
          background-color: ${colors.white};

          ${toastSign}
        }

        .${ns}__close-button {
          color: ${colors.n100};

          &:hover {
            color: ${colors.white};
          }
        }
      }

      &--info {
        background-color: ${colors.white};

        &::before {
          background-color: ${colors.b900};

          ${toastSign}
        }

        & .${ns}__progress-bar {
          background: ${colors.b050};
        }
      }

      &--success {
        background-color: ${colors.white};

        &::before {
          background-color: ${colors.g900};

          ${toastSign}
        }

        & .${ns}__progress-bar {
          background: ${colors.g050};
        }
      }

      &--warning {
        background-color: ${colors.white};

        &::before {
          background-color: ${colors.y900};

          ${toastSign}
        }

        & .${ns}__progress-bar {
          background: ${colors.y050};
        }
      }

      &--error {
        background-color: ${colors.white};

        &::before {
          background-color: ${colors.r900};

          ${toastSign}
        }

        & .${ns}__progress-bar {
          background: ${colors.r050};
        }
      }
    }

    &__progress-bar {
      right: 0;
      width: calc(100% - 8px);
      left: auto;

      &--dark {
        background: ${colors.n050};
      }

      &--default {
        background: ${colors.n050};
      }
    }
  }
`
