import CookieConsent from 'react-cookie-consent'
import { Translation } from '@hfs/i18n/components/Translation'
import { colors } from '@hfs/ui/tokens/colors'
import { fontFamily } from '@hfs/ui/tokens/typography'

const typography = { fontFamily, fontSize: '14px', lineHeight: '16px' }

export const GdprBanner = () => (
  <CookieConsent
    buttonStyle={{ ...typography, backgroundColor: colors.g900, color: colors.white }}
    buttonText={<Translation translationId="global.gdpr.accept" />}
    declineButtonStyle={{ ...typography, backgroundColor: colors.r900 }}
    declineButtonText={<Translation translationId="global.gdpr.decline" />}
    enableDeclineButton
    sameSite="lax"
    style={typography}
  >
    <Translation translationId="global.gdpr" />
  </CookieConsent>
)
