import styled from 'styled-components'

export const Svg = styled.svg``

export { Circle } from './Circle'
export { Defs } from './Defs'
export { G } from './G'
export { Image } from './Image'
export { Pattern } from './Pattern'
export { Rect } from './Rect'
