import styled from 'styled-components'
import { Circle as CircleBase, Svg as SvgBase } from '@hfs/ui/components/Svg'
import { colors, TColorName } from '@hfs/ui/tokens/colors'

const Svg = styled(SvgBase)<{ height: number; width: number }>`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
`

const Circle = styled(CircleBase)<{ isLoading: boolean }>`
  @keyframes rotate {
    from {
      transform: rotate(0deg);
      transform-origin: 50px 50px;
    }

    to {
      transform: rotate(360deg);
      transform-origin: 50px 50px;
    }
  }

  animation-direction: normal;
  animation-duration: 750ms;
  animation-iteration-count: infinite;
  animation-name: rotate;
  animation-play-state: ${({ isLoading }) => (isLoading ? 'running' : 'paused')};
  animation-timing-function: linear;
`

type TProps = {
  height: number
  fill: TColorName
  loading?: boolean
  width: number
}

export const Loader = ({ height, fill, loading = false, width }: TProps) => (
  <Svg height={height} viewBox="0 0 100 100" width={width}>
    <Circle
      cx="50"
      cy="50"
      fill="none"
      isLoading={loading}
      r="35"
      stroke={colors[fill]}
      strokeDasharray="164.93361431346415 56.97787143782138"
      strokeWidth="6"
    />
  </Svg>
)
