import { PropsWithChildren } from 'react'
import { createGlobalStyle } from 'styled-components'
import { fontFamily } from '@hfs/ui/tokens/typography'
import { reactDayPicker } from './react-day-picker'
import { toastify } from './toastify'

const Style = createGlobalStyle`
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: ${fontFamily};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  html, body {
    height: 100%;
    margin: 0;
    width: 100%;
  }

  #root {
    display: flex;
    flex-grow: 1;
    height: 100%;
  }

  * {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    text-rendering: optimizeLegibility;

    &,
    &::before,
    &::after {
      box-sizing: border-box;
    }

    a {
      &, &:active, &:focus {
        outline: none;
        text-decoration: none;
      }
    }

    button, input {
      outline: none;

      &::-moz-focus-inner {
        border: 0px;
      }
    }

    input {
      &[type="date"]::-webkit-inner-spin-button,
      &[type="date"]::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
      }
    }
  }

  ${reactDayPicker}
  ${toastify}
`

export const GlobalStyle = ({ children }: PropsWithChildren<Record<string, unknown>>) => (
  <>
    <Style />

    {children}
  </>
)
