export const fontFamily = "'Helvetica Neue', Verdana, Arial, sans-serif"

export const typographyFontSize = [14, 16, 18, 24, 32, 40, 56] as const

export type TTypographyFontSize = typeof typographyFontSize[number]

export const typographyFontWeight = [400, 500, 700, 900] as const

export type TTypographyFontWeight = typeof typographyFontWeight[number]

export const typographyLineHeight = [20, 24, 28, 32, 38, 48, 60] as const

export type TTypographyLineHeight = typeof typographyLineHeight[number]
