import { lazy } from 'react'
import { Route } from '@hfs/react-router'
import { paths } from '@hfs/website/routes/paths'

export const contractsAndRules = [
  <Route
    key={paths.contractsAndRules}
    component={lazy(async () => {
      const { ContractsAndRules } = await import(
        /* webpackPrefetch: true, webpackChunkName: "ContractsAndRules" */
        '@hfs/website/components/pages/public/ContractsAndRules'
      )

      return {
        default: ContractsAndRules,
      }
    })}
    exact
    path={paths.contractsAndRules}
  />,
]
