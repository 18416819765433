import { TTranslationProps, useTranslation } from '@hfs/i18n/hooks/useTranslation'

type TProps = TTranslationProps

export const Translation = ({ translationId, translationValues }: TProps) => {
  const { formattedMessage } = useTranslation({
    translationId,
    translationValues,
  })

  return <>{formattedMessage}</>
}
