type TParam = {
  alpha?: number
  hex: string
}

const getChannelFromHex = (hex: string) => (number: number) => {
  const firstOffset = hex.length === 4 ? number : number * 2 - 1
  const secondOffset = hex.length === 4 ? number : number * 2

  return parseInt(`0x${hex[firstOffset]}${hex[secondOffset]}`, 16)
}

export const hexToRgba = ({ alpha = 1, hex }: TParam) => {
  const getChannel = getChannelFromHex(hex)

  const rgba = [getChannel(1), getChannel(2), getChannel(3), alpha]

  return `rgba(${rgba.join(', ')})`
}
