import { css } from 'styled-components'
import { colors } from '@hfs/ui/tokens/colors'
import { fontFamily } from '@hfs/ui/tokens/typography'

const ns = '.DayPicker'
const nsDay = `${ns}-Day`
const nsNavButton = `${ns}-NavButton`
const nsWrapper = `${ns}-wrapper`

export const reactDayPicker = css`
  ${ns} {
    font-family: ${fontFamily};

    & ${nsNavButton} {
      outline: none;
    }

    & ${nsWrapper} {
      outline: none;
    }

    & ${nsDay} {
      outline: none;

      &:not(${nsDay}--disabled):not(${nsDay}--selected):hover {
        background-color: ${colors.r050} !important;
        color: white;
      }
    }
  }
`
