import { forwardRef, MouseEvent, ReactNode } from 'react'
import styled from 'styled-components'
import { View } from '@hfs/ui/components/View'
import { useViewport } from '@hfs/ui/hooks/useViewport'
import { background, TBackgroundParams } from '@hfs/ui/utils/style/background'
import { border, TBorderParams } from '@hfs/ui/utils/style/border'
import { TFlexParam } from '@hfs/ui/utils/style/flex'
import { padding, TPaddingParams } from '@hfs/ui/utils/style/padding'

type TProps = TBackgroundParams &
  TFlexParam &
  TBorderParams &
  TPaddingParams & {
    children?: ReactNode
    className?: string
    onClick?: (e: MouseEvent<HTMLDivElement>) => any | Promise<any>
  }

const defaultProps: TProps = {
  align: 'stretch',
  alignSelf: 'auto',
  basis: 'auto',
  flexDir: 'column',
  flexWrap: 'nowrap',
  grow: 0,
  justify: 'flex-start',
  justifySelf: 'auto',
  shrink: 0,
}

const ColumnComponent = styled(View).attrs<TProps>(props => ({
  ...defaultProps,
  ...props,
}))<TProps>`
  ${background}
  ${border}
  ${padding}
`

export const Column = forwardRef<HTMLDivElement, TProps>((props, ref) => {
  const { isDesktop, isTablet } = useViewport()

  return <ColumnComponent {...props} ref={ref} isDesktop={isDesktop} isTablet={isTablet} />
})
