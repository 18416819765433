import { lazy } from 'react'
import { Route } from '@hfs/react-router'
import { paths } from '@hfs/website/routes/paths'

export const contactUs = [
  <Route
    key={paths.contactUs}
    component={lazy(async () => {
      const { ContactUs } = await import(
        /* webpackPrefetch: true, webpackChunkName: "ContactUs" */ '@hfs/website/components/pages/public/ContactUs'
      )

      return {
        default: ContactUs,
      }
    })}
    exact
    path={paths.contactUs}
  />,
]
