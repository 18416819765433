import { aboutUs } from './aboutUs'
import { contactUs } from './contactUs'
import { contractsAndRules } from './contractsAndRules'
import { homepage } from './homepage'
import { news } from './news'
import { noMatch } from './noMatch'
import { rooms } from './rooms'

export const routesConfiguration: JSX.Element[] = [
  ...aboutUs,
  ...contactUs,
  ...contractsAndRules,
  ...homepage,
  ...news,
  ...rooms,
  ...noMatch, // NOTE: it's mandatory that the noMatch route must be the last one
]
