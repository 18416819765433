import { ReactNode, useEffect, useState, useMemo } from 'react'
import { IntlProvider as IntlProviderBase } from 'react-intl'
import { TTranslationValues } from '@hfs/i18n/types'
import { Country, Language } from '@hfs/types/locale'
import { executeAsyncEffect } from '@hfs/utils/functions/executeAsyncEffect'

type Props = {
  children: ReactNode
  country: Country
  getTranslations: (locale: string) => Promise<TTranslationValues>
  language: Language
}

export const IntlProvider = ({ children, country, getTranslations, language }: Props) => {
  const locale = useMemo(() => `${language}-${country}`, [country, language])

  const [messages, setMessages] = useState<TTranslationValues | undefined>()

  useEffect(() => {
    if (messages) {
      return
    }

    executeAsyncEffect(async () => {
      setMessages(await getTranslations(locale))
    })
  }, [getTranslations, locale, messages, setMessages])

  if (!messages) {
    return null
  }

  return (
    <IntlProviderBase locale={language} messages={messages}>
      {children}
    </IntlProviderBase>
  )
}
