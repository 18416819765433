import { css } from 'styled-components'
import { TSpacing } from '@hfs/ui/tokens/spacing'

type TPaddingConfig = {
  padding?: string
  paddingBottom?: string
  paddingLeft?: string
  paddingRight?: string
  paddingTop?: string
}

type TParam = {
  desktopPadding?: TSpacing
  desktopPaddingBottom?: TSpacing
  desktopPaddingLeft?: TSpacing
  desktopPaddingRight?: TSpacing
  desktopPaddingTop?: TSpacing
  isDesktop?: boolean
  isTablet?: boolean
  padding?: TSpacing
  paddingBottom?: TSpacing
  paddingLeft?: TSpacing
  paddingRight?: TSpacing
  paddingTop?: TSpacing
  tabletPadding?: TSpacing
  tabletPaddingBottom?: TSpacing
  tabletPaddingLeft?: TSpacing
  tabletPaddingRight?: TSpacing
  tabletPaddingTop?: TSpacing
}

const getNormalizedPadding = (padding?: TSpacing) => {
  if (!padding) {
    return ''
  }

  if (typeof padding === 'number') {
    return `${padding}px`
  }

  return padding
}

const getPadding = ({
  desktopPadding,
  desktopPaddingBottom,
  desktopPaddingLeft,
  desktopPaddingRight,
  desktopPaddingTop,
  isDesktop,
  isTablet,
  padding,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  tabletPadding,
  tabletPaddingBottom,
  tabletPaddingLeft,
  tabletPaddingRight,
  tabletPaddingTop,
}: TParam) => {
  let paddingConfig: TPaddingConfig = {
    ...(padding !== undefined && { padding: getNormalizedPadding(padding) }),
    ...(paddingBottom !== undefined && { paddingBottom: getNormalizedPadding(paddingBottom) }),
    ...(paddingLeft !== undefined && { paddingLeft: getNormalizedPadding(paddingLeft) }),
    ...(paddingRight !== undefined && { paddingRight: getNormalizedPadding(paddingRight) }),
    ...(paddingTop !== undefined && { paddingTop: getNormalizedPadding(paddingTop) }),
  }

  if (isTablet) {
    paddingConfig = {
      ...paddingConfig,
      ...(tabletPadding !== undefined && { padding: getNormalizedPadding(tabletPadding) }),
      ...(tabletPaddingBottom !== undefined && { paddingBottom: getNormalizedPadding(tabletPaddingBottom) }),
      ...(tabletPaddingLeft !== undefined && { paddingLeft: getNormalizedPadding(tabletPaddingLeft) }),
      ...(tabletPaddingRight !== undefined && { paddingRight: getNormalizedPadding(tabletPaddingRight) }),
      ...(tabletPaddingTop !== undefined && { paddingTop: getNormalizedPadding(tabletPaddingTop) }),
    }
  }

  if (isDesktop) {
    paddingConfig = {
      ...paddingConfig,
      ...(desktopPadding !== undefined && { padding: getNormalizedPadding(desktopPadding) }),
      ...(desktopPaddingBottom !== undefined && { paddingBottom: getNormalizedPadding(desktopPaddingBottom) }),
      ...(desktopPaddingLeft !== undefined && { paddingLeft: getNormalizedPadding(desktopPaddingLeft) }),
      ...(desktopPaddingRight !== undefined && { paddingRight: getNormalizedPadding(desktopPaddingRight) }),
      ...(desktopPaddingTop !== undefined && { paddingTop: getNormalizedPadding(desktopPaddingTop) }),
    }
  }

  return `
    ${paddingConfig.padding ? `padding: ${paddingConfig.padding};` : ''}
    ${paddingConfig.paddingBottom ? `padding-bottom: ${paddingConfig.paddingBottom};` : ''}
    ${paddingConfig.paddingLeft ? `padding-left: ${paddingConfig.paddingLeft};` : ''}
    ${paddingConfig.paddingRight ? `padding-right: ${paddingConfig.paddingRight};` : ''}
    ${paddingConfig.paddingTop ? `padding-top: ${paddingConfig.paddingTop};` : ''}
  `
}

export const padding = css<TParam>`
  ${getPadding}
`

export type TPaddingParams = TParam
