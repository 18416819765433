import { fold } from 'fp-ts/lib/Either'
import { pipe } from 'fp-ts/lib/pipeable'
import { identity } from 'io-ts'
import { environmentCodec } from '../environmentCodec'
import { getCurrentEnvironment } from '../getCurrentEnvironment'

export const environment = (() =>
  pipe(
    process.env.ENVIRONMENT,
    environmentCodec.decode,
    fold(() => 'development' as const, identity),
    getCurrentEnvironment
  ))()
